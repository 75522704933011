/*
 * @author Sergey Kuzhavskiy <Praffesor>
 */
angular.module('landing.pricing', ['data.security-provider']).config(['$stateProvider', $stateProvider => {

  // temporarily disabled
  $stateProvider
    .state('landing.pricing', {
      url: '/pricing_old',
      template: require('./template.pug'),
      controller: 'LandingPricingController',
      controllerAs: 'landingPricingController',
    })

}]);
