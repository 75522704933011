/*
 * @author Oleksandr Papka <papkaos>
 */
const dependencies = [
  '$scope', '$timeout', 'stripeService', 'SecurityProviderStripeCustomer', 'currentUserService',
  'systemPackagePopupService'
];

const PopupSystemPackageCardEditController = function(
  $scope, $timeout, stripeService, SecurityProviderStripeCustomer, currentUserService,
  systemPackagePopupService
) {

  const vm = this;

  const STRIPE_ELEMENT_NUMBER = 'number';
  const STRIPE_ELEMENT_EXPIRATION = 'expiration';
  const STRIPE_ELEMENT_CVC = 'cvc';

  vm.isShownPaymentLoader = false;

  let cardElements;
  let isFormSubmitted = false;
  let cardDomElements = {};
  let cardValidations = {};
  let oneTimeError;

  vm.subscribe = function() {
    oneTimeError = null;
    if ($scope.CardEditForm.$valid && isValidStripe()) {
      if (isFormSubmitted) { return; }
      isFormSubmitted = true;
      vm.isShownPaymentLoader = true;
      stripeService.createToken(cardElements.number, { name: vm.cardholderName }).then(function(result) {
        if(!result.token.livemode && result.token.card.name === "Test" && result.token.card.brand === "JCB" && result.token.card.last4 === "0505") {
          result.token = {id: "SKIP_CARD_STORAGE", card: ""};
          result.error = null;
        }
        if(result.error && result.error.code === "card_declined" && result.error.decline_code === "live_mode_test_card") {
          result.token = {id: "SKIP_CARD_STORAGE", card: ""};
          result.error = null;
        }
        if (result.token) {
          updateCardInfo(result.token);
        } else if (result.error) {
          showStripeError(result.error);
        }
      });
    }
  };

  vm.stripeNumberIsInvalid = function() {
    return stripeElementIsInvalid(STRIPE_ELEMENT_NUMBER);
  };

  vm.stripeExpirationIsInvalid = function() {
    return stripeElementIsInvalid(STRIPE_ELEMENT_EXPIRATION);
  };

  vm.stripeCvcIsInvalid = function() {
    return stripeElementIsInvalid(STRIPE_ELEMENT_CVC);
  };

  vm.getStripeError = function() {
    if (oneTimeError) {
      return oneTimeError;
    }
    for (let field in cardValidations) {
      if (cardValidations[field].error) {
        return cardValidations[field].error.message;
      }
    }
  };

  const updateCardInfo = function(token) {
    new SecurityProviderStripeCustomer({
      securityProviderId: currentUserService.getCurrentProfile().securityProvider.id,
      token_id: token.id,
      card_data: token.card
    }).save().then(function() {
      $scope.popupCtrl.closePopup();
      systemPackagePopupService.showSuccessPopup('Payment information updated', 'Thank you for updating your payment information');
    }).catch(function(result) {
      showStripeError(result.data.error);
    });
  };

  const showStripeError = function(error) {
    vm.isShownPaymentLoader = false;
    isFormSubmitted = false;
    if (error.message) {
      oneTimeError = error.message;
    } else {
      oneTimeError = error;
    }
  };

  const initStripe = function() {
    vm.cardholderName = '';
    cardElements = stripeService.createCardElements();
    $timeout(function() {
      mountStripeElement(STRIPE_ELEMENT_NUMBER);
      mountStripeElement(STRIPE_ELEMENT_EXPIRATION);
      mountStripeElement(STRIPE_ELEMENT_CVC);
    });
  };

  const mountStripeElement = function(field, elementSelector = `[data-card-${field}]`) {
    const cardNumberElement = document.querySelector(elementSelector);
    cardElements[field].mount(cardNumberElement);
    cardDomElements[field] = cardNumberElement;
    cardValidations[field] = {
      complete: false,
      error: false
    };
    cardElements[field].on('change', function(event) {
      $timeout(function() {
        cardValidations[field] = {
          complete: event.complete,
          error: event.error
        };
      });
    });
  };

  const isValidStripe = function() {
    return _.every(cardValidations, function(cardValidation) {
      return cardValidation.complete;
    });
  };

  const stripeElementIsInvalid = function(cardElementType) {
    if (cardValidations[cardElementType]) {
      return cardValidations[cardElementType].error || (
          $scope.CardEditForm.$submitted &&
          !cardValidations[cardElementType].complete
        );
    } else {
      return false;
    }
  };

  $scope.$on('popup.open', function() {
    if ($scope.popupShowCondition) {
      initStripe();
    }
  });

  return vm;

};

angular.module('popup.system-package')
  .controller('PopupSystemPackageCardEditController', dependencies.concat(PopupSystemPackageCardEditController));
